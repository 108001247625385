import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  show(event) {
    const targetWrapper =
      event.currentTarget.getElementsByClassName("wrapper")[0];
    const targetChevron =
      event.currentTarget.getElementsByClassName("fa")[0];

    if (targetWrapper.className === "wrapper") {
      targetWrapper.className = "wrapper is-open";
      targetChevron.className = "fa fa-chevron-up"
    } else {
      targetWrapper.className = "wrapper";
      targetChevron.className = "fa fa-chevron-down"
    }
  }
}
